import type { ReactNode } from "react";

export type Props = { title: string; children: ReactNode };

function ErrorMessage({ title, children }: Props) {
  return (
    <div className="rounded bg-red px-6 py-5 text-sm">
      <h2 className="mb-3 text-lg font-medium">{title}</h2>
      {children}
    </div>
  );
}

export default ErrorMessage;
