import { id } from "./Main";

export type Props = Record<string, never>;

function SkipToContent() {
  return (
    <a
      className="absolute -top-full flex h-16 items-center justify-center p-4 focus:top-0 focus:bg-green focus:text-white"
      href={`#${id}`}
    >
      Skip to content
    </a>
  );
}

export default SkipToContent;
