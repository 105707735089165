import type { ReactNode } from "react";

import ErrorMessage from "../errors/ErrorMessage";
import Layout from "../Layout";

export type Props = { title: string; children: ReactNode };

function Err({ title, children }: Props) {
  return (
    <Layout>
      <ErrorMessage title={title}>{children}</ErrorMessage>
    </Layout>
  );
}

export default Err;
