import { useCallback, useEffect, useReducer, useRef } from "react";

/**
 * Custom hook that allows changing state using a reducer on events,
 * ensuring that events are processed only if the component is mounted.
 * If the component is unmounted, dispatched events are ignored and reducer isn't called.
 *
 * I see it as an optimization to avoid processing leaked events dispatches that shouldn't affect anything.
 */
export default function useSafeState<StateType, ActionType>(
  reducer: (state: StateType, action: ActionType) => StateType,
  initialState: StateType,
): [StateType, (action: ActionType) => void] {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [state, dispatchUnsafe] = useReducer(reducer, initialState);

  const dispatch = useCallback(
    (action: ActionType) => {
      if (mounted.current) dispatchUnsafe(action);
    },
    [mounted],
  );

  return [state, dispatch];
}
