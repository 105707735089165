import type { ErrorInfo, ReactNode } from "react";
import { Component } from "react";

export type Props = {
  fallback?: (error: Error) => ReactNode;
  children: ReactNode;
};

export type State = { error?: Error };

class ErrorBoundary extends Component<Props, State> {
  state = { error: undefined };

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // TODO: Log error details to Sentry, Bugsnag or a similar service
    console.error(error, info); // eslint-disable-line no-console
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (error) {
      return fallback ? fallback(error) : null;
    }

    return children;
  }
}

export default ErrorBoundary;
