import { useCallback, useEffect, useReducer, useRef, useState } from "react";

export type AsyncFn<T> = () => Promise<T>;

export type AsyncState<T> =
  | {
      loading: true;
      error: undefined;
      value: undefined;
    }
  | {
      loading: false;
      error: Error;
      value: undefined;
    }
  | {
      loading: false;
      error: undefined;
      value: T;
    };

const initial = { loading: true, value: undefined, error: undefined } as const;

export default function useAsyncRetry<T>(fn: AsyncFn<T>, deps: unknown[]) {
  const [attempt, next] = useReducer((s: number) => s + 1, 0);
  const [state, set] = useState<AsyncState<T>>(initial);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = useCallback(() => fn(), [attempt, ...deps]);

  const retry = useCallback(() => {
    set(initial);
    next();
  }, []);

  const handle = useRef<AsyncFn<T>>();

  useEffect(() => {
    handle.current = action;

    action()
      .then((value) => {
        if (handle.current !== action) return;
        set({ loading: false, error: undefined, value });
      })
      .catch((error: Error) => {
        if (handle.current !== action) return;
        set({ loading: false, error, value: undefined });
      });
  }, [action]);

  return { ...state, retry };
}
