import type { ReactNode } from "react";
import { StrictMode } from "react";

import ErrorBoundary from "./errors/ErrorBoundary";

function fallback(error: Error) {
  return (
    <div>
      {error.name}: {error.message}
    </div>
  );
}

export type Props = { children: ReactNode };

function Envelope({ children }: Props) {
  return (
    <StrictMode>
      <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>
    </StrictMode>
  );
}

export default Envelope;
