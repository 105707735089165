import Logo from "./logo.svg";

export type Props = Record<string, never>;

function Header() {
  return (
    <header className="mb-12 flex items-center justify-between gap-2 text-lg font-normal">
      <span className="flex items-center">
        <Logo className="h-16 w-16" />
        <span className="px-4">JetBrains Check-In</span>
      </span>
    </header>
  );
}

export default Header;
