import { BrowserQRCodeReader } from "@zxing/library";
import { useMemo } from "react";
import { useAsync } from "react-use";

import useAsyncRetry from "./util/useAsyncRetry";

export type DeviceInfo = Omit<MediaDeviceInfo, "toJSON">;

export type ScanState =
  | {
      scanning: true;
      error: undefined;
      code: undefined;
    }
  | {
      scanning: false;
      error: Error;
      code: undefined;
    }
  | {
      scanning: false;
      error: undefined;
      code: string;
    };

export type State = {
  devices: DeviceInfo[] | undefined;
  retry: () => void;
} & ScanState;

export default function useQRCodeReader(
  video: HTMLVideoElement | undefined,
  device: DeviceInfo | undefined,
): State {
  const reader = useMemo(() => new BrowserQRCodeReader(), []);

  const devices = useAsync(() => reader.listVideoInputDevices(), []);

  const scan = useAsyncRetry(async () => {
    if (video && device) {
      return reader.decodeFromInputVideoDevice(device.deviceId, video);
    }

    return undefined;
  }, [reader, video, device]);

  const state = useMemo<ScanState>(() => {
    if (scan.loading) {
      return {
        scanning: true,
        error: undefined,
        code: undefined,
      };
    }

    if (scan.value) {
      return {
        scanning: false,
        error: undefined,
        code: scan.value.getText(),
      };
    }

    return {
      scanning: false,
      error: scan.error ?? new Error("invalid code"),
      code: undefined,
    };
  }, [scan.loading, scan.value, scan.error]);

  return {
    devices: devices.value,
    retry: scan.retry,
    ...state,
  };
}
