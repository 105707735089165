import { Menu } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import {
  CheckCircleIcon as SuccessIcon,
  Cog6ToothIcon as SettingsIcon,
  EllipsisHorizontalIcon as InitializingIcon,
  ExclamationCircleIcon as NotificationIcon,
  IdentificationIcon as KeyCardIcon,
  LinkIcon,
  QrCodeIcon,
  ViewfinderCircleIcon as ScanIcon,
  XCircleIcon as ErrorIcon,
} from "@heroicons/react/24/outline";
import { formatRelative as formatDateRelative } from "date-fns/formatRelative";
import { parseISO as parseISODate } from "date-fns/parseISO";
import { useCallback, useEffect } from "react";
import { useLocalStorage } from "react-use";

import { apiUrl } from "../apiUrl";
import DocumentTitle from "../layout/DocumentTitle";
import link from "../link";
import {
  Bottom,
  Button,
  Container,
  Counter,
  defaultIconProps,
  FocusFrame,
  Notification,
  Panel,
  Status,
  Top,
  Video,
} from "../ScannerComponents";
import type { Code, KeyCard, Place } from "../types";
import useExternalReader from "../useExternalReader";
import type { DeviceInfo } from "../useQRCodeReader";
import useQRCodeReader from "../useQRCodeReader";
import useSafeState from "../useSafeState";
import useVideoElementRef from "../useVideoElementRef";
import verify from "../verify";

const endpoints = {
  linker: `${apiUrl}/link`,
  verifier: `${apiUrl}/verify`,
} as const;

export type Mode = "default" | "kiosk";
export type Props = { place: Place };

export type Result = {
  type: "error" | "success" | "warning";
  title: string;
  message: string;
  initials: string | undefined;
};

export type State =
  | { name: "scanning" }
  | { name: "verifying code"; code: string }
  | { name: "verifying card"; card: string }
  | { name: "confirm you have qr to be linked to the card"; card: string }
  | { name: "action required"; card: string; countdown: number }
  | { name: "linking card"; card: string; code: string }
  | { name: "result"; result: Result; countdown: number };

export type Action =
  | { name: "code read"; code: string }
  | { name: "card read"; card: string }
  | { name: "card not registered" }
  | { name: "qr is in hands" }
  | { name: "resolved"; result: Result }
  | { name: "tick" }
  | { name: "cancel" };

const linkQrToCardCountdown = 30;
const linkQrToCardButtonText = "Link";

function reducer(state: State, action: Action): State {
  switch (action.name) {
    case "cancel":
      return { name: "scanning" };
    case "resolved":
      return {
        name: "result",
        result: action.result,
        countdown: action.result.type === "success" ? 2 : 5,
      };
  }

  switch (state.name) {
    case "scanning":
      if (action.name === "card read") {
        return { name: "verifying card", card: action.card };
      }
      if (action.name === "code read") {
        return { name: "verifying code", code: action.code };
      }
      break;
    case "verifying card":
      if (action.name === "card not registered") {
        return {
          name: "confirm you have qr to be linked to the card",
          card: state.card,
        };
      }
      break;
    case "confirm you have qr to be linked to the card":
      if (action.name === "qr is in hands") {
        return {
          name: "action required",
          card: state.card,
          countdown: linkQrToCardCountdown,
        };
      }
      break;
    case "action required":
      if (action.name === "code read")
        return { name: "linking card", card: state.card, code: action.code };
      if (action.name === "tick")
        return { ...state, countdown: state.countdown - 1 };
      break;
    case "result":
      if (action.name === "tick")
        return { ...state, countdown: state.countdown - 1 };
      break;
  }
  return state;
}

function isScanning(state: State): boolean {
  return state.name === "scanning" || state.name === "action required";
}

function toMessage(error: Error) {
  if (error.cause && typeof error.cause === "object") {
    const info = error.cause;
    let message = "";

    if ("reason" in info && typeof info.reason === "string") {
      message = info.reason;
    }

    if ("timestamp" in info && typeof info.timestamp === "string") {
      const date = parseISODate(info.timestamp);
      message += ` ${formatDateRelative(date, Date.now())}`;
    }

    if (message !== "") return message;
  }

  return error.message;
}

function toResult(error: unknown): Result {
  return {
    type: "error",
    title: "Error",
    message: error instanceof Error ? toMessage(error) : "Unknown error",
    initials:
      (error instanceof Error &&
        "cause" in error &&
        typeof error.cause === "object" &&
        error.cause &&
        "initials" in error.cause &&
        typeof error.cause.initials === "string" &&
        error.cause.initials.toString()) ||
      undefined,
  };
}

async function linkAndVerify(
  card: string,
  code: string,
  place: Place,
): Promise<Result> {
  const keyCard: KeyCard = { type: "keycard", value: card };
  const target: Code = { type: "code", value: code };
  await link(keyCard, target, endpoints.linker);
  try {
    const data = await verify(keyCard, place, endpoints.verifier);
    return {
      type: data.type === "ok" ? "success" : "warning",
      title: "Linked & verified",
      message: data.type === "ok" ? "checked-in with key card" : data.reason,
      initials: data.initials,
    };
  } catch {
    return {
      type: "success",
      title: "Linked",
      message: "you’re all set",
      initials: undefined,
    };
  }
}

function Scanner({ place }: Props) {
  const [state, dispatch] = useSafeState(reducer, { name: "scanning" });

  const [mode, set] = useLocalStorage<Mode>("scan-mode", "default");

  const [device, pick] = useLocalStorage<DeviceInfo>("media-device");
  const video = useVideoElementRef(null);

  const qr = useQRCodeReader(video.current ?? undefined, device);
  const card = useExternalReader();

  const scanning = isScanning(state);

  const cancel = useCallback(() => {
    qr.retry();
    card.retry();
    dispatch({ name: "cancel" });
  }, [dispatch, qr, card]);

  const qrIsInHands = useCallback(() => {
    dispatch({ name: "qr is in hands" });
  }, [dispatch]);

  // Once we have a list of available video devices, pick the one we last used.
  // If we have not used any device before, pick one facing the environment.
  // If no such device is found, use the first one available.
  // Users can select a different video input device in the menu.
  useEffect(() => {
    if (qr.devices) {
      const choice =
        qr.devices.find(({ deviceId }) => deviceId === device?.deviceId) ??
        qr.devices.find(({ label }) => /back|environment|rear/gi.test(label)) ??
        qr.devices[0];

      pick(choice);
    }
  }, [qr.devices, pick, device?.deviceId]);

  const initialized = qr.devices && qr.devices.length > 0 && video.current;

  // for countdown states, dispatch "cancel" if timeout reached zero
  useEffect(() => {
    if (
      (state.name === "result" || state.name === "action required") &&
      state.countdown <= 0
    ) {
      cancel();
    }
  }, [state, cancel]);

  useEffect(() => {
    // perform necessary calls
    switch (state.name) {
      case "verifying card":
        verify(
          { type: "keycard", value: state.card },
          place,
          endpoints.verifier,
        )
          .then((data) => {
            dispatch({
              name: "resolved",
              result: {
                type: data.type === "ok" ? "success" : "warning",
                title: "Verified",
                message:
                  data.type === "ok" ? "check-in successful" : data.reason,
                initials: data.initials,
              },
            });
          })
          .catch((e) => {
            const cause = e instanceof Error ? e.cause : undefined;

            const reason =
              typeof cause === "object" && cause !== null && "reason" in cause
                ? cause.reason
                : undefined;

            if (reason === "key card not registered") {
              dispatch({ name: "card not registered" });
            } else {
              dispatch({ name: "resolved", result: toResult(e) });
            }
          });
        break;
      case "verifying code":
        verify({ type: "code", value: state.code }, place, endpoints.verifier)
          .then(() =>
            dispatch({
              name: "resolved",
              result: {
                type: "success",
                title: "Verified",
                message: "check-in successful",
                initials: undefined,
              },
            }),
          )
          .catch((e) => dispatch({ name: "resolved", result: toResult(e) }));
        break;
      case "linking card":
        linkAndVerify(state.card, state.code, place)
          .then((result) => dispatch({ name: "resolved", result: result }))
          .catch((e) => dispatch({ name: "resolved", result: toResult(e) }));
        break;
    }
  }, [dispatch, state, place]);

  // for kiosk mode and countdown states, set timeout for "tick" dispatch
  useEffect(() => {
    if (
      mode === "kiosk" &&
      (state.name === "result" || state.name === "action required") &&
      state.countdown > 0
    ) {
      const timeout = setTimeout(() => {
        dispatch({ name: "tick" });
      }, 1000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [dispatch, mode, state]);

  // in a scanning state, process scan results
  useEffect(() => {
    if (state.name === "scanning" && card.code) {
      dispatch({ name: "card read", card: card.code });
    } else if (scanning && qr.code)
      dispatch({ name: "code read", code: qr.code });
  }, [dispatch, state.name, scanning, qr.code, card.code]);

  return (
    <Container>
      <DocumentTitle value="JetBrains Check-In" />

      <Video ref={video} blur={!scanning} />

      {initialized && scanning && <FocusFrame />}

      <Top>
        <div className="flex items-center justify-end px-3 py-4">
          <Menu as="div" className="relative inline-block">
            <Menu.Button
              type="button"
              className="flex items-center justify-center rounded-full bg-black/85 p-2 text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-green focus-visible:ring-opacity-75"
            >
              <SettingsIcon
                {...defaultIconProps}
                className="h-6 w-6 shrink-0 transition hover:rotate-90"
              />
              <span className="sr-only">Settings</span>
            </Menu.Button>
            <Menu.Items className="absolute right-0 mt-2 origin-top-right divide-y divide-light/25 rounded-md bg-black font-light text-white shadow-lg focus:outline-none">
              <div className="p-1">
                {qr.devices?.map((option) => (
                  <Menu.Item key={option.deviceId}>
                    <button
                      type="button"
                      onClick={() => pick(option)}
                      className="flex w-full items-center justify-between gap-2 whitespace-nowrap rounded-md px-3 py-2 text-left hover:bg-white hover:text-black"
                    >
                      {option.label}
                      {option.deviceId === device?.deviceId && (
                        <>
                          <span className="sr-only">(selected)</span>
                          <CheckIcon
                            {...defaultIconProps}
                            className="h-5 w-5 shrink-0"
                          />
                        </>
                      )}
                    </button>
                  </Menu.Item>
                ))}
              </div>
              <div className="p-1">
                <Menu.Item>
                  <button
                    type="button"
                    onClick={() =>
                      mode === "kiosk" ? set("default") : set("kiosk")
                    }
                    className="flex w-full items-center justify-between whitespace-nowrap rounded-md px-3 py-2 text-left hover:bg-white hover:text-black"
                  >
                    {mode === "default" ? "Enable" : "Disable"} kiosk-mode
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </Top>

      <Bottom>
        {state.name === "action required" && (
          <Notification>
            <QrCodeIcon {...defaultIconProps} className="h-5 w-5" />
            Scan a QR code to link your key card.
          </Notification>
        )}

        <Panel
          state={
            state.name === "result"
              ? state.result.type
              : state.name === "confirm you have qr to be linked to the card"
                ? "warning"
                : "default"
          }
        >
          {!initialized ? (
            <Status
              icon={InitializingIcon}
              title="Initializing…"
              message="this should not take long"
            />
          ) : state.name === "linking card" ? (
            <Status
              icon={LinkIcon}
              title="Linking…"
              message="connecting key card to identity"
            />
          ) : state.name === "verifying card" ? (
            <Status
              icon={KeyCardIcon}
              title="Verifying…"
              message="checking identity and eligibility"
            />
          ) : state.name === "verifying code" ? (
            <Status
              icon={QrCodeIcon}
              title="Verifying…"
              message="checking identity and eligibility"
            />
          ) : state.name === "result" ? (
            <>
              {state.result.initials ? (
                <Status
                  icon={state.result.initials}
                  title={state.result.title}
                  message={state.result.message}
                />
              ) : (
                <Status
                  icon={state.result.type === "error" ? ErrorIcon : SuccessIcon}
                  title={state.result.title}
                  message={state.result.message}
                />
              )}
              <Button onClick={cancel}>
                {mode === "kiosk" ? (
                  <Counter seconds={state.countdown} />
                ) : (
                  "OK"
                )}
              </Button>
            </>
          ) : state.name === "confirm you have qr to be linked to the card" ? (
            <>
              <Status
                icon={QrCodeIcon}
                title="Unknown card needs QR code linking"
                message={`please prepare your QR code to link to this card. Generate your QR code at https://id.intdev.intellij.net/. When you are ready, press ${linkQrToCardButtonText} to proceed to linking: you will then have ${linkQrToCardCountdown} seconds to scan your QR code.`}
              />
              <Button onClick={qrIsInHands}>{linkQrToCardButtonText}</Button>
              <Button onClick={cancel}>Cancel</Button>
            </>
          ) : state.name === "action required" ? (
            <>
              <Status
                icon={NotificationIcon}
                title="Action required"
                message="key card not registered"
              />
              <Button onClick={cancel}>
                {mode === "kiosk" ? (
                  <Counter seconds={state.countdown} />
                ) : (
                  "OK"
                )}
              </Button>
            </>
          ) : (
            <Status
              icon={ScanIcon}
              title="Scanning…"
              message="looking for a proof of identity"
            />
          )}
        </Panel>
      </Bottom>
    </Container>
  );
}

export default Scanner;
