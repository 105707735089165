import { useEffect } from "react";

import { apiUrl } from "../apiUrl";
import DocumentTitle from "../layout/DocumentTitle";
import { Container, Notification } from "../ScannerComponents";
import type { Code, KeyCard } from "../types";
import useSafeState from "../useSafeState";
import adminAction from "./admin/adminAction";
import AdminLogin from "./admin/AdminLogin";
import type { CardInfoResult } from "./admin/cardInfo";
import cardInfo from "./admin/cardInfo";
import type { ServerAction } from "./admin/CardManagement";
import CardManagement from "./admin/CardManagement";
import EnterCard from "./admin/EnterCard";
import type { WelcomeDataResult } from "./admin/login";
import login from "./admin/login";

const endpoints = {
  blocker: `${apiUrl}/block`,
  info: `${apiUrl}/info`,
  isAdmin: `${apiUrl}/is-admin`,
  unlinker: `${apiUrl}/unlink`,
} as const;

type State =
  | { name: "waiting for admin qr code"; message: string | undefined }
  | { name: "waiting for server auth"; code: Code }
  | {
      name: "waiting for card to manage";
      adminCode: Code;
      welcomeData: WelcomeDataResult;
      message: string | undefined;
    }
  | {
      name: "waiting for card info from server";
      adminCode: Code;
      welcomeData: WelcomeDataResult;
      card: KeyCard;
    }
  | {
      name: "waiting for action for card";
      adminCode: Code;
      welcomeData: WelcomeDataResult;
      cardInfo: CardInfoResult;
      card: KeyCard;
    }
  | {
      name: "waiting for action handling from server";
      adminCode: Code;
      welcomeData: WelcomeDataResult;
      cardInfo: CardInfoResult;
      actionEndpoint: string;
      action: ServerAction;
      card: KeyCard;
    };

type Action =
  | { name: "admin qr code read"; code: Code }
  | {
      name: "admin qr code valid";
      code: Code;
      welcomeData: WelcomeDataResult;
    }
  | { name: "admin qr code invalid"; reason: string | undefined }
  | { name: "card to manage read"; card: KeyCard }
  | { name: "card info received"; info: CardInfoResult }
  | { name: "card info error"; reason: string | undefined }
  | { name: "block clicked" }
  | { name: "forget clicked" }
  | { name: "manage another card clicked" }
  | { name: "action handling success" }
  | { name: "action handling error"; reason: string | undefined };

function reducer(state: State, action: Action): State {
  switch (state.name) {
    case "waiting for admin qr code":
      if (action.name === "admin qr code read") {
        return { name: "waiting for server auth", code: action.code };
      }
      break;

    case "waiting for server auth":
      if (action.name === "admin qr code valid") {
        return {
          name: "waiting for card to manage",
          adminCode: action.code,
          welcomeData: action.welcomeData,
          message: undefined,
        };
      }
      if (action.name === "admin qr code invalid") {
        return {
          name: "waiting for admin qr code",
          message: `invalid admin QR code: ${
            action.reason ?? "unknown problem, please contact support..."
          }`,
        };
      }
      break;

    case "waiting for card to manage":
      if (action.name === "card to manage read") {
        return {
          name: "waiting for card info from server",
          adminCode: state.adminCode,
          welcomeData: state.welcomeData,
          card: action.card,
        };
      }
      break;

    case "waiting for card info from server":
      if (action.name === "card info received") {
        return {
          name: "waiting for action for card",
          adminCode: state.adminCode,
          welcomeData: state.welcomeData,
          cardInfo: action.info,
          card: state.card,
        };
      }
      if (action.name === "card info error") {
        return {
          name: "waiting for card to manage",
          adminCode: state.adminCode,
          welcomeData: state.welcomeData,
          message: `can't obtain card info: ${
            action.reason ?? "unknown problem, please contact support..."
          }`,
        };
      }
      break;

    case "waiting for action for card":
      if (action.name === "manage another card clicked") {
        return {
          name: "waiting for card to manage",
          adminCode: state.adminCode,
          welcomeData: state.welcomeData,
          message: undefined,
        };
      }
      if (action.name === "block clicked") {
        return {
          name: "waiting for action handling from server",
          adminCode: state.adminCode,
          welcomeData: state.welcomeData,
          cardInfo: state.cardInfo,
          card: state.card,
          actionEndpoint: endpoints.blocker,
          action: "block",
        };
      }
      if (action.name === "forget clicked") {
        return {
          name: "waiting for action handling from server",
          adminCode: state.adminCode,
          welcomeData: state.welcomeData,
          cardInfo: state.cardInfo,
          card: state.card,
          actionEndpoint: endpoints.unlinker,
          action: "unlink",
        };
      }
      break;

    case "waiting for action handling from server":
      if (action.name === "action handling success") {
        // let's go to the state where the card info requested again, so the actual state will be shown
        return {
          name: "waiting for card info from server",
          adminCode: state.adminCode,
          welcomeData: state.welcomeData,
          card: state.card,
        };
      }
      if (action.name === "action handling error") {
        return {
          name: "waiting for card to manage", // todo: we could better handle the problem, e.g. detect status
          adminCode: state.adminCode,
          welcomeData: state.welcomeData,
          message: `can't ${state.action}: ${
            action.reason ?? "unknown problem, please contact support..."
          }`,
        };
      }
      break;
  }
  return state;
}

export default function Admin() {
  const [state, dispatch] = useSafeState(reducer, {
    name: "waiting for admin qr code",
    message: undefined,
  });

  useEffect(() => {
    switch (state.name) {
      case "waiting for server auth":
        void (async () => {
          try {
            const welcomeData = await login(state.code, endpoints.isAdmin);
            dispatch({
              name: "admin qr code valid",
              code: state.code,
              welcomeData: welcomeData,
            });
          } catch (e) {
            const reason = e instanceof Error ? e.message : undefined;
            dispatch({ name: "admin qr code invalid", reason: reason });
          }
        })();
        break;
      case "waiting for card info from server":
        void (async () => {
          try {
            const cardInfoResult = await cardInfo(
              state.adminCode,
              state.card,
              endpoints.info,
            );
            dispatch({ name: "card info received", info: cardInfoResult });
          } catch (e) {
            const reason = e instanceof Error ? e.message : undefined;
            dispatch({ name: "card info error", reason: reason });
          }
        })();
        break;
      case "waiting for action handling from server":
        void (async () => {
          try {
            await adminAction(
              state.adminCode,
              state.card,
              state.actionEndpoint,
            );
            dispatch({ name: "action handling success" });
          } catch (e) {
            const reason = e instanceof Error ? e.message : undefined;
            dispatch({ name: "action handling error", reason: reason });
          }
        })();
        break;
    }
  }, [dispatch, state]);

  return (
    <div className="h-viewport">
      <DocumentTitle value="JetBrains Check-In Admin" />
      {state.name == "waiting for admin qr code" && (
        // todo: looks like camera continues to work even when we leave this state, so let's search for the leak.
        //       btw, can it be that the same happens in the scanner, when we reset it after every verified card?
        //       it could explain why the scanner starts being slow after a while
        //       (Lesha had exactly a concern that there is a leak somewhere in the scanner).
        <AdminLogin
          onQrCode={(code) =>
            dispatch({ name: "admin qr code read", code: code })
          }
          message={state.message}
        />
      )}

      {state.name == "waiting for server auth" && (
        <Container>
          <Notification>Waiting for server auth...</Notification>
          {/*todo: consider reusing previous screen, e.g. just adding blur*/}
        </Container>
      )}

      {state.name == "waiting for card to manage" && (
        <EnterCard
          onCardScan={(serial) =>
            dispatch({ name: "card to manage read", card: serial })
          }
          welcomeData={state.welcomeData}
        />
      )}

      {state.name == "waiting for card info from server" && (
        <Container>
          <Notification>Waiting for card info from server...</Notification>
          {/*todo: consider reusing previous screen, e.g. just adding spinner*/}
        </Container>
      )}

      {state.name == "waiting for action for card" && (
        <CardManagement
          cardInfo={state.cardInfo}
          onBlockClick={() => dispatch({ name: "block clicked" })}
          onForgetClick={() => dispatch({ name: "forget clicked" })}
          onAnotherCardClick={() =>
            dispatch({ name: "manage another card clicked" })
          }
          currentServerAction={undefined}
        />
      )}

      {state.name == "waiting for action handling from server" && (
        <CardManagement
          cardInfo={state.cardInfo}
          onBlockClick={() => {}}
          onForgetClick={() => {}}
          onAnotherCardClick={() => {}}
          currentServerAction={state.action}
        />
      )}
    </div>
  );
}
