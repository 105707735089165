import {
  ArrowPathIcon,
  ExclamationCircleIcon as NotificationIcon,
  QrCodeIcon,
} from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useLocalStorage } from "react-use";

import {
  Bottom,
  Container,
  defaultIconProps,
  FocusFrame,
  Notification,
  Panel,
  Status,
  Top,
  Video,
} from "../../ScannerComponents";
import type { Code } from "../../types";
import type { DeviceInfo } from "../../useQRCodeReader";
import useQRCodeReader from "../../useQRCodeReader";
import useVideoElementRef from "../../useVideoElementRef";

export type Props = {
  onQrCode: (code: Code) => void;
  message: string | undefined;
};

export default function AdminLogin({ onQrCode, message }: Props) {
  const [device, pick] = useLocalStorage<DeviceInfo>("media-device");
  const video = useVideoElementRef(null);

  const qr = useQRCodeReader(video.current ?? undefined, device);

  function pickNextDevice() {
    pick((previousDevice) => {
      if (!qr.devices) return previousDevice;

      const previousIdx = qr.devices.findIndex(
        ({ deviceId }) => deviceId === previousDevice?.deviceId,
      );

      const nextIdx = (previousIdx + 1) % qr.devices.length; // 0 if previousDevice is not found
      return qr.devices[nextIdx];
    });
  }

  // Once we have a list of available video devices, pick the one we last used.
  // If we have not used any device before, pick one facing the environment.
  // If no such device is found, use the first one available.
  // Users can select a different video input device in the menu.
  useEffect(() => {
    if (qr.devices) {
      const choice =
        qr.devices.find(({ deviceId }) => deviceId === device?.deviceId) ??
        qr.devices.find(({ label }) => /back|environment|rear/gi.test(label)) ??
        qr.devices[0];

      pick(choice);
    }
  }, [qr.devices, pick, device?.deviceId]);

  const initialized = qr.devices && qr.devices.length > 0 && video.current;

  useEffect(() => {
    if (qr.code) onQrCode({ type: "code", value: qr.code });
  }, [onQrCode, qr.code]);

  return (
    <Container>
      <Video ref={video} blur={false} />

      {initialized && <FocusFrame />}

      <Top>
        <div className="flex items-center justify-end px-3 py-4">
          <button
            type="button"
            onClick={pickNextDevice}
            className="flex items-center justify-center rounded-full bg-black/85 p-2 text-white transition hover:rotate-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-green focus-visible:ring-opacity-75"
          >
            <ArrowPathIcon {...defaultIconProps} className="h-6 w-6 shrink-0" />
            <span className="sr-only">Switch camera</span>
          </button>
        </div>
      </Top>

      <Bottom>
        <Notification>
          <QrCodeIcon {...defaultIconProps} className="h-5 w-5" />
          Scan your recent QR code (not older than 12 hours) to login to the
          admin interface.
        </Notification>

        {message && (
          <Panel state="error">
            <Status icon={NotificationIcon} title={message} />
          </Panel>
        )}
      </Bottom>
    </Container>
  );
}
