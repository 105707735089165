import clsx from "clsx";
import type {
  ForwardedRef,
  MouseEventHandler,
  PropsWithoutRef,
  ReactNode,
  SVGAttributes,
  SVGProps,
} from "react";
import { forwardRef } from "react";

import Countdown from "./time/Countdown";

const defaultIconProps: SVGAttributes<SVGElement> = {
  "aria-hidden": true,
  focusable: "false",
};

function Top({ children }: { children: ReactNode }) {
  return <div className="fixed left-0 right-0 top-0">{children}</div>;
}

function Bottom({ children }: { children: ReactNode }) {
  return <div className="fixed bottom-0 left-0 right-0">{children}</div>;
}

function Panel({
  children,
  state,
}: {
  children: ReactNode;
  state: "default" | "error" | "success" | "warning";
}) {
  return (
    <div
      className={clsx(
        "flex min-h-28 w-full items-center justify-between gap-3 p-6 text-lg md:min-h-36",
        {
          "bg-red/85": state === "error",
          "bg-yellow/85": state === "warning",
          "bg-black/85": state === "default",
          "bg-green/85": state === "success",
        },
      )}
    >
      {children}
    </div>
  );
}

type SVGIcon = (props: PropsWithoutRef<SVGProps<SVGSVGElement>>) => ReactNode;

function Avatar({
  initials,
  className,
}: {
  initials: string;
  className?: string;
}) {
  return (
    <div className={className}>
      <div className="flex h-full w-full items-center justify-center rounded-lg border border-2 md:rounded-xl md:border-[3px]">
        <span className="font-mono text-sm uppercase md:text-2xl md:font-bold">
          {initials}
        </span>
      </div>
    </div>
  );
}

function Status({
  icon: Icon,
  title,
  message,
}: {
  icon: SVGIcon | string;
  title: string;
  message?: string;
}) {
  return (
    <div className="flex items-center gap-2 md:gap-3">
      {typeof Icon === "string" ? (
        <Avatar initials={Icon} className="h-8 w-8 shrink-0 md:h-14 md:w-14" />
      ) : (
        <Icon
          {...defaultIconProps}
          className="h-8 w-8 shrink-0 md:h-14 md:w-14"
        />
      )}
      <div className="flex flex-col text-base md:text-3xl">
        <span className="md:tracking-tight">{title}</span>
        {message && (
          <span className="text-xs first-letter:uppercase md:text-base">
            {message}
          </span>
        )}
      </div>
    </div>
  );
}

function Button({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <button
      type="button"
      className="flex items-center rounded-full border border-white bg-none px-8 py-4 md:border-2 md:font-normal"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function Counter({ seconds }: { seconds: number }) {
  return <Countdown className="inline-block min-w-4" seconds={seconds} />;
}

function Container({ children }: { children: ReactNode }) {
  return (
    <div className="relative flex h-viewport w-full items-center justify-center bg-black">
      {children}
    </div>
  );
}

function VideoFn(
  props: { blur: boolean },
  ref: ForwardedRef<HTMLVideoElement>,
) {
  return (
    /* eslint-disable jsx-a11y/media-has-caption */
    <video
      className={clsx("h-viewport w-screen object-cover", props.blur && "blur")}
      ref={ref}
    />
    /* eslint-enable jsx-a11y/media-has-caption */
  );
}

const Video = Object.assign(forwardRef(VideoFn), { displayName: "Video" });

function FocusFrame() {
  return (
    <div className="absolute portrait:w-3/4 landscape:h-3/4">
      <div className="relative mb-5 aspect-square h-full">
        <div className="absolute left-0 top-0 h-6 w-6 rounded-tl-md border-l-2 border-t-2 border-yellow" />
        <div className="absolute right-0 top-0 h-6 w-6 rounded-tr-md border-r-2 border-t-2 border-yellow" />
        <div className="absolute bottom-0 left-0 h-6 w-6 rounded-bl-md border-b-2 border-l-2 border-yellow" />
        <div className="absolute bottom-0 right-0 h-6 w-6 rounded-br-md border-b-2 border-r-2 border-yellow" />
      </div>
    </div>
  );
}

function Notification({ children }: { children: ReactNode }) {
  return (
    <div className="flex justify-center px-6 py-2">
      <div className="flex items-center gap-2 rounded-full bg-yellow px-3 py-2 text-center text-xs text-black">
        {children}
      </div>
    </div>
  );
}

export {
  Bottom,
  Button,
  Container,
  Counter,
  defaultIconProps,
  FocusFrame,
  Notification,
  Panel,
  Status,
  Top,
  Video,
};
