import clsx from "clsx";
import { parseISO as parseISODate } from "date-fns/parseISO";
import { useAsync } from "react-use";

import { apiUrl } from "../apiUrl";
import ErrorMessage from "../errors/ErrorMessage";
import Layout from "../Layout";
import FormattedDateTime from "../time/FormattedDateTime";
import type { Place } from "../types";

export type Props = { place: Place };

type StatsResponse = {
  stats: { date: string; count: number }[];
};

const endpoint =
  process.env["STATS_ENDPOINT_URL"] || // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
  `${apiUrl}/stats`;

function useStats(place: Place) {
  return useAsync(async () => {
    const params = new URLSearchParams();
    params.append("place", place.id);

    const search = params.toString();

    const response = await fetch(`${endpoint}?${search}`);

    if (!response.ok) {
      throw new Error("Request failed");
    }

    const data = (await response.json()) as StatsResponse;

    return data.stats
      .map(({ date, count }) => ({ date: parseISODate(date), count }))
      .sort((a, b) => b.date.getTime() - a.date.getTime());
  }, [place]);
}

function Stats({ place }: Props) {
  const stats = useStats(place);

  const [today, ...rest] = stats.value ?? [];

  return (
    <Layout>
      {stats.loading ? (
        <span className="text-lg font-medium">Loading…</span>
      ) : stats.error ? (
        <ErrorMessage title="Ouch!">{stats.error.message}</ErrorMessage>
      ) : stats.value?.length === 0 ? (
        <ErrorMessage title="Missing data!">
          Received an empty response.
        </ErrorMessage>
      ) : (
        <ul className="-mt-8 w-full">
          {today && (
            <li className="flex flex-col items-center justify-center border-t-0 py-8">
              <div className="mb-3 flex aspect-square h-24 w-24 items-center justify-center rounded-full bg-white p-5 text-5xl font-medium text-main">
                {today.count} <span className="sr-only">codes scanned</span>
              </div>
              <FormattedDateTime
                className="text-sm text-white/50"
                value={today.date}
                format="d MMMM, EEEE"
              />
            </li>
          )}
          {rest.map(({ date, count }) => (
            <li
              key={date.toISOString()}
              className="border-t border-light/25 px-2 py-2"
            >
              <div
                className={clsx(
                  "flex items-center justify-between",
                  count === 0 && "opacity-10",
                )}
              >
                <span className="text-md text-white/75">
                  <FormattedDateTime value={date} format="d MMMM" />
                  <FormattedDateTime
                    className="ml-2 text-sm text-white/50"
                    value={date}
                    format="EEE"
                  />
                </span>
                <span className="text-2xl font-medium">
                  {count} <span className="sr-only">codes scanned</span>
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Layout>
  );
}

export default Stats;
