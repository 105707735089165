import { formatISODuration } from "date-fns/formatISODuration";
import type { TimeHTMLAttributes } from "react";
import { useEffect, useMemo, useState } from "react";

export type Props = Omit<
  TimeHTMLAttributes<HTMLTimeElement>,
  "children" | "dateTime"
> & { seconds: number };

function Countdown({ seconds: initial, ...props }: Props) {
  const [seconds, set] = useState(initial);

  const iso = useMemo(() => formatISODuration({ seconds }), [seconds]);

  useEffect(() => {
    if (seconds > 0) {
      const timeout = setTimeout(() => set(seconds - 1), 1000);
      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [seconds]);

  return (
    <time {...props} dateTime={iso}>
      {seconds}
    </time>
  );
}

export default Countdown;
