import { useCallback, useEffect, useRef, useState } from "react";

import { ufrCardReader } from "./cardSerialFormat";

const valid = (tag: string) => ufrCardReader.test(tag);

export type ScanState =
  | { scanning: true; code: undefined }
  | { scanning: false; code: string };

export type State = { retry: () => void } & ScanState;

const initialState: ScanState = { scanning: true, code: undefined };

export default function useExternalReader(): State {
  const [state, setState] = useState<ScanState>(initialState);

  const buffer = useRef<string>("");
  const paused = useRef<boolean>(false);

  const retry = useCallback(() => {
    buffer.current = "";
    paused.current = false;

    setState(initialState);
  }, []);

  const submit = useCallback(
    (tag: string) => {
      if (!valid(tag)) return retry();

      paused.current = true;

      setState({ scanning: false, code: tag });
    },
    [retry],
  );

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (paused.current) {
        return;
      }

      if (event.key === "Enter") {
        return submit(buffer.current);
      }

      buffer.current += event.key;
    };

    document.addEventListener("keypress", listener);

    return () => document.removeEventListener("keypress", listener);
  }, [submit]);

  return { retry, ...state };
}
