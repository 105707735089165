import clsx from "clsx";
import type { FormEvent } from "react";
import { useCallback, useMemo, useState } from "react";

import { nfcToolsApp } from "../../cardSerialFormat";
import type { KeyCard } from "../../types";
import type { WelcomeDataResult } from "./login";

const validator = (serial: string) => nfcToolsApp.test(serial);

export type Props = {
  onCardScan: (serial: KeyCard) => void;
  welcomeData: WelcomeDataResult;
};

export default function EnterCard({ onCardScan, welcomeData }: Props) {
  const [input, setInput] = useState("");
  const isValidInput = useMemo(() => validator(input), [input]);

  const validateAndSubmit = useCallback(
    (e: FormEvent) => {
      if (isValidInput) {
        const serial = input.split(":").reverse().join("").toLowerCase();
        onCardScan({ type: "keycard", value: serial });
      }
      e.preventDefault();
    },
    [input, isValidInput, onCardScan],
  );

  return (
    <form
      className="flex min-h-full w-full flex-col bg-white p-5 text-main"
      onSubmit={validateAndSubmit}
    >
      <p className="self-center">
        Hello <span className="font-bold">{welcomeData.name}</span>!
      </p>
      {welcomeData.avatarDataUrl && (
        <img
          src={welcomeData.avatarDataUrl}
          alt="Avatar"
          className="my-2.5 block size-16 self-center rounded-full object-cover"
        />
      )}
      <p>
        Welcome to the Check-Ins Admin Interface. At the moment, it&apos;s for{" "}
        <strong>Check-Ins Tablet only</strong> (not for doors etc.).
      </p>
      <input
        placeholder="Input card serial number here"
        type="text"
        id="cardSerial"
        className={clsx(
          "mt-8 w-full self-center rounded border px-4 py-2.5 text-center font-mono",
          {
            "border-black": isValidInput,
            "border-red": !isValidInput,
          },
        )}
        onChange={(e) => {
          const newValue = e.currentTarget.value;
          setInput(newValue);
        }}
      />
      <button
        className="mt-1 w-full self-center rounded border border-black bg-green px-4 py-2.5 disabled:cursor-not-allowed disabled:border-red disabled:bg-white"
        disabled={!isValidInput}
      >
        Manage this card!
      </button>
      <p className="mb-4 mt-8">
        Manual:
        <ol className="list-inside list-decimal">
          <li>
            <strong>Install</strong> NFC Tools app (get it for{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.wakdev.wdnfc&hl=en-US"
              className="font-bold text-blue"
            >
              Android
            </a>{" "}
            or{" "}
            <a
              href="https://apps.apple.com/us/app/nfc-tools/id1252962749"
              className="font-bold text-blue"
            >
              iOS
            </a>
            ).
          </li>
          <li>
            <strong>Open</strong> the app and touch your phone with the card (or
            tag) to scan it.
          </li>
          <li>
            <strong>Copy</strong> the serial number of the card (tap on it and
            choose <em>copy</em>).
          </li>
          <li>
            <strong>Paste</strong> the serial number in the text field above.
          </li>
          <li>
            <strong>Click</strong> the button below the text field (or press{" "}
            <kbd>Enter</kbd>).
          </li>
        </ol>
      </p>
      <label htmlFor="cardSerial">
        The following formats of card serial numbers are supported (where{" "}
        <code>x</code> can be a <code>digit</code> or a letter from{" "}
        <code>A</code> to <code>F</code>):
      </label>
      <ul className="mb-1 list-disc self-center">
        <li>
          <code>04:xx:xx:xx:xx:xx:80</code>
        </li>
        <li>
          <code>04:xx:xx:xx:xx:xx:90</code>
        </li>
      </ul>
    </form>
  );
}
