import { CameraIcon } from "@heroicons/react/24/outline";

import Layout from "../Layout";
import type { Place } from "../types";

export type Props = { place: Place; next: () => void };

function Start({ place, next }: Props) {
  return (
    <Layout>
      <h2 className="mb-2 text-center text-lg">{place.name}</h2>
      <h3 className="mb-6 text-center text-sm text-light">{place.id}</h3>
      <button
        type="button"
        className="my-6 flex items-center gap-2 rounded-full bg-green px-8 py-4 text-white hover:bg-green/90 active:bg-green/90"
        onClick={next}
      >
        <CameraIcon className="h-6 w-6" focusable="false" aria-hidden />
        Start scanning
      </button>
      <p className="text-center text-sm text-light">
        You will be asked for camera access.
      </p>
    </Layout>
  );
}

export default Start;
