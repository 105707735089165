import type { Code, KeyCard } from "../../types";

export default async function adminAction(
  adminCode: Code,
  card: KeyCard,
  url: string,
) {
  const response = await fetch(url, {
    // todo: create wrapper for requests
    mode: "cors",
    method: "POST",
    headers: {
      "content-type": "application/json",
      "X-Code": adminCode.value,
    },
    body: JSON.stringify(card),
  });

  if (!response.ok) {
    const body = await response.text();
    throw new Error(`${body} (admin action status ${response.status})`);
  }
}
