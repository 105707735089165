import type { ReactNode } from "react";

import DocumentTitle from "./layout/DocumentTitle";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import Main from "./layout/Main";
import SkipToContent from "./layout/SkipToContent";

function Container({ children }: { children: ReactNode }) {
  return <div className="m-auto max-w-2xl px-4">{children}</div>;
}

export type Props = { children: ReactNode };

function Layout({ children }: Props) {
  return (
    <Container>
      <DocumentTitle value="JetBrains Check-In" />
      <SkipToContent />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
}

export default Layout;
