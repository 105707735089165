import { useEffect } from "react";
import { useAsync } from "react-use";

import Layout from "../Layout";

function reload() {
  window.location.reload(); // Note: This does not seem to work in Firefox (Desktop).
}

export type Props = { next: () => void };

function Setup({ next }: Props) {
  const permitted = useAsync(async () => {
    try {
      // Prompt user for permission to use a video input device (camera).
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (error) {
      return false;
    }
  });

  useEffect(() => {
    if (permitted.value) {
      next();
    }
  }, [next, permitted.value]);

  return (
    <Layout>
      <h2 className="mb-2 text-center font-medium">
        Allow the app to use your camera
      </h2>
      <p className="mb-6 text-center text-light">
        The app needs access to your camera so it can scan and verify codes.
      </p>
      <button
        type="button"
        className="flex items-center rounded-full border border-white px-4 py-2"
        onClick={reload}
      >
        Try again
      </button>
    </Layout>
  );
}

export default Setup;
