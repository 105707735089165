import type { Identity } from "./types";

export type VerificationResult =
  | { type: "error"; reason: string; timestamp?: string }
  | { type: "warning"; reason: string; initials?: string }
  | { type: "ok"; initials?: string };

export function valid(data: unknown): data is VerificationResult {
  if (typeof data !== "object" || data === null) return false;
  if (!("type" in data) || typeof data.type !== "string") return false;
  return ["ok", "error", "warning"].includes(data.type);
}

export default async function verify(
  identity: Identity,
  place: { id: string },
  endpoint: string,
) {
  const params = new URLSearchParams();
  params.append("place", place.id);

  const search = params.toString();

  const url = search ? `${endpoint}?${search}` : endpoint;

  const response = await fetch(url, {
    mode: "cors",
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(identity),
  });

  if (!response.ok) {
    throw new Error(`Request failed (${response.status})`);
  }

  const data: unknown = await response.json();

  if (!valid(data)) {
    throw new Error("Invalid response");
  }

  if (data.type === "error") {
    throw new Error("Invalid identity", { cause: data });
  }

  return data;
}
