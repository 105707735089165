import type { Code, KeyCard } from "./types";

export type LinkingResult = { type: "error"; reason: string } | { type: "ok" };

export function valid(data: unknown): data is LinkingResult {
  if (typeof data !== "object" || data === null) return false;
  if (!("type" in data) || typeof data.type !== "string") return false;
  return ["ok", "error"].includes(data.type);
}

export default async function link(
  keyCard: KeyCard,
  target: Code,
  endpoint: string,
) {
  const response = await fetch(endpoint, {
    mode: "cors",
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ keyCard, target }),
  });

  if (!response.ok) {
    throw new Error(`Request failed (${response.status})`);
  }

  const data: unknown = await response.json();

  if (!valid(data)) {
    throw new Error("Invalid response");
  }

  if (data.type === "error") {
    throw new Error("Linking error", { cause: data });
  }

  return data;
}
