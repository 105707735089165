import { useEffect } from "react";

export type Props = { value: string };

function DocumentTitle({ value }: Props) {
  useEffect(() => {
    const original = document.title;
    document.title = value;
    return () => {
      document.title = original;
    };
  }, [value]);

  return null;
}

export default DocumentTitle;
