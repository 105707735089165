import { format as formatDate } from "date-fns/format";
import { formatISO } from "date-fns/formatISO";
import { parseISO } from "date-fns/parseISO";
import type { TimeHTMLAttributes } from "react";
import { useMemo } from "react";

export type Props = Omit<
  TimeHTMLAttributes<HTMLTimeElement>,
  "children" | "dateTime"
> & {
  value: Date | string;
  format: string;
};

function FormattedDateTime({ value, format, ...props }: Props) {
  const date = useMemo(
    () => (typeof value === "string" ? parseISO(value) : value),
    [value],
  );

  const iso = useMemo(() => formatISO(date), [date]);
  const formatted = useMemo(() => formatDate(date, format), [date, format]);

  return (
    <time {...props} dateTime={iso}>
      {formatted}
    </time>
  );
}

export default FormattedDateTime;
