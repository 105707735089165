import { useMemo, useState } from "react";

import Envelope from "./Envelope";
import Admin from "./screens/Admin";
import Err from "./screens/Err";
import Scanner from "./screens/Scanner";
import Setup from "./screens/Setup";
import Start from "./screens/Start";
import Stats from "./screens/Stats";
import type { Place } from "./types";

export type Props = Record<string, never>;

type State = "initial" | "setup" | "scanning";

function Root() {
  const [state, goto] = useState<State>("initial");

  const place = useMemo<Place | null>(() => {
    const params = new URLSearchParams(location.search);

    const id = params.get("id");
    const name = params.get("name");

    if (typeof id !== "string" || typeof name !== "string") {
      return null;
    }

    return { id, name };
  }, []);

  const pathname = location.pathname;

  return (
    <Envelope>
      {pathname === "/admin" ? (
        <Admin />
      ) : place ? (
        pathname === "/stats" ? (
          <Stats place={place} />
        ) : state === "scanning" ? (
          <Scanner place={place} />
        ) : state === "setup" ? (
          <Setup next={() => goto("scanning")} />
        ) : (
          <Start place={place} next={() => goto("setup")} />
        )
      ) : (
        <Err title="Place details not found">
          <p className="mb-2">
            Please make sure you opened the correct link or check back with the
            person you received it from:
          </p>
          <code>{location.href}</code>
        </Err>
      )}
    </Envelope>
  );
}

export default Root;
