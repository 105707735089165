import type { Code, KeyCard } from "../../types";

export type CardInfoResult =
  | { type: "unknown" }
  | { type: "guest" }
  | { type: "found"; name: string; avatarDataUrl?: string; profileUrl: string };

export default async function cardInfo(
  adminCode: Code,
  card: KeyCard,
  url: string,
) {
  const response = await fetch(url, {
    // todo: create wrapper for requests
    mode: "cors",
    method: "POST",
    headers: {
      "content-type": "application/json",
      "X-Code": adminCode.value,
    },
    body: JSON.stringify(card),
  });

  if (!response.ok) {
    const body = await response.text();
    throw new Error(`${body} (card info status ${response.status})`);
  }

  return (await response.json()) as CardInfoResult;
}
