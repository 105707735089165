import type { ButtonHTMLAttributes, HTMLAttributes, ReactNode } from "react";

import type { CardInfoResult } from "./cardInfo";

export type ServerAction = "block" | "unlink";

type HoverableButtonProps = {
  disabled: boolean;
  children: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

function HoverableButton({
  disabled,
  children,
  ...rest
}: HoverableButtonProps) {
  return (
    <li>
      <button
        disabled={disabled}
        className="rounded border border-solid border-blue bg-white px-4 py-2.5 text-blue transition hover:bg-blue hover:text-white disabled:cursor-not-allowed disabled:border-main disabled:bg-white disabled:text-pale"
        {...rest}
      >
        {children}
      </button>
    </li>
  );
}

type InfoTextProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLParagraphElement>;

function InfoText({ children, ...rest }: InfoTextProps) {
  return (
    <p className="mb-3 text-base" {...rest}>
      {children}
    </p>
  );
}

type CardInfoProps = {
  cardInfo: CardInfoResult;
};

function CardInfo({ cardInfo }: CardInfoProps) {
  return (
    <div className="mb-5 flex flex-col items-center">
      {cardInfo.type === "unknown" && (
        <InfoText>
          New card (can be linked to an employee on a check-ins tablet; then
          used for check-ins).
        </InfoText>
      )}

      {cardInfo.type === "guest" && (
        <InfoText>
          Guest card (can <em>not</em> be linked to an employee on a check-ins
          tablet; check-ins are impossible).
        </InfoText>
      )}

      {cardInfo.type === "found" && (
        <>
          <InfoText>
            Card linked to{" "}
            <a
              href={cardInfo.profileUrl}
              className="font-bold text-blue no-underline"
            >
              {cardInfo.name}
            </a>
            .
          </InfoText>
          {cardInfo.avatarDataUrl && (
            <a href={cardInfo.profileUrl}>
              <img
                src={cardInfo.avatarDataUrl}
                alt="Avatar"
                className="mt-2.5 block size-16 rounded-full object-cover"
              />
            </a>
          )}
        </>
      )}
    </div>
  );
}

export type Props = {
  cardInfo: CardInfoResult;
  onBlockClick: () => void;
  onForgetClick: () => void;
  onAnotherCardClick: () => void;
  currentServerAction: ServerAction | undefined;
};

export default function CardManagement({
  cardInfo,
  onBlockClick,
  onForgetClick,
  onAnotherCardClick,
  currentServerAction,
}: Props) {
  return (
    <div className="flex min-h-full w-full flex-col items-center justify-center bg-white p-5 text-main">
      <CardInfo cardInfo={cardInfo} />
      <p className="mb-4 text-lg font-medium">Mark card as...</p>
      <ul className="flex flex-col items-center gap-2.5">
        <HoverableButton
          onClick={onBlockClick}
          disabled={
            currentServerAction !== undefined || cardInfo.type === "guest"
          }
        >
          <em>Guest</em> – make check-ins impossible
          {currentServerAction === "block" && "..."}
        </HoverableButton>

        <HoverableButton
          onClick={onForgetClick}
          disabled={
            currentServerAction !== undefined || cardInfo.type === "unknown"
          }
        >
          <em>Completely New</em> – an employee can link the card; then
          check-ins will be possible
          {currentServerAction === "unlink" && "..."}
        </HoverableButton>
      </ul>

      <div className="h-8">{/* Spacer */}</div>

      <button
        onClick={onAnotherCardClick}
        disabled={currentServerAction !== undefined}
        className="px-4 py-2.5 text-blue hover:text-green disabled:cursor-not-allowed disabled:text-pale"
      >
        Manage another card
      </button>
    </div>
  );
}
