export type Props = Record<string, never>;

function Footer() {
  return (
    <footer className="mb-16 flex flex-col items-center justify-between gap-2 p-2 text-xs text-light/50 sm:flex-row">
      <span>A JetBrains service</span>
      <span>Created by the Internal Development Team</span>
    </footer>
  );
}

export default Footer;
