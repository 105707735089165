import type { Code } from "../../types";

export type WelcomeDataResult = { name: string; avatarDataUrl?: string };

export default async function login(code: Code, url: string) {
  const response = await fetch(url, {
    // todo: create wrapper for requests
    mode: "cors",
    method: "GET",
    headers: {
      "content-type": "application/json",
      "X-Code": code.value,
    },
  });

  if (!response.ok) {
    const body = await response.text();
    throw new Error(`${body} (login status ${response.status})`);
  }

  return (await response.json()) as WelcomeDataResult;
}
